<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-icon
        class="grey--text cursor-pointer"
        :left="left"
        v-on="on"
        v-clipboard:copy="data"
        v-clipboard:success="onCopySuccess">
        fa fa-copy
      </v-icon>
    </template>
    <span>{{tooltip| t}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    left: {
      type: Boolean,
      default: true,
    },
    data: {
      default: () => null,
    },
    tooltip: {
      type: String,
      default: 'action.copy',
    },
  },
  methods: {
    onCopySuccess() {
      this.$snotify.success(
        this.data,
        this.$t('copy.successfully')
      )
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>